@import 'app/styles/breakpoints';
@import 'app/styles/global/mixins';
@import "app/styles/global/variables";

.root {
  display: grid;
  grid-template-rows: auto 1fr;
  margin-bottom: 50px;
}

.formWrapper {
  margin-top: 50px;
  display: grid;
  grid-template-rows: auto auto;
  gap: 10px;

  @include tablet {
    gap: 0;
  }
}

.error {
  color: red;
}

.formGrid {
  position: relative;
  display: grid;
  justify-content: start;
  grid-template-rows: repeat(6, auto);
  gap: 40px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    padding-left: unset;
  }
  @include tablet {
    gap: 20px;
  }
}

.gap30 {
  gap: 30px;
}

.tooltipContent {
  max-width: max-content;
}

.rowText {
  color: var(--dark-color);
}

.columnBtn {
  display: flex;
  gap: 5px;
  flex-direction: column;

  @include tablet {
    gap: 0;
  }
}

.row {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 126px 1fr 258px;
  gap: 20px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 10px;
  }
}

.row:last-child {
  align-items: start;
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.link {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.row:last-child {
  align-items: start;
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.link {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.activeInput {
  border-color: var(--primary-btn-color) !important;
}

.customInputHeight {
  height: 38px;
}

.specialistField {
  margin-top: 20px;
  justify-content: unset;
}

.dateSelect {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 5px;
}

.gridSelect {
  display: grid;
  grid-template-columns: 183px 127px;
  gap: 20px;
}

.underText {
  width: 70%;
  border-bottom: 1px dashed var(--gray-color);
}

.codeBtn {
  color: var(--primary-btn-color);
}

.btnWrapper {
  background-color: #fffde9;
  border-radius: 60px;
  width: 100%;
  justify-content: start;
  padding: 15px;
  display: grid;
  align-items: center;
  grid-template-columns: 126px auto 1fr;
  gap: 25px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    grid-template-columns: auto;
    justify-content: center;
    text-align: center;
    grid-template-rows: auto auto auto;
    gap: 10px;
  }
}

.completeOrderUz {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fffde9;
  border-radius: 60px;
  padding: 30px;
  width: 100%;
  gap: 25px;
}

.btn {
  padding: 15px 57px;
  height: unset !important;
}

.costWrapper {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 14px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    gap: 10px;
  }
}

.btnClose {
  padding: 4px;
  border-radius: 0 6px 6px 0;
  border: 1px solid #d9d9d9;
}

.closeIcon {
  width: 24px;
  height: 24px;
  mask-image: url('../../../1_shared/assets/pictures/close.svg');
  background-color: var(--primary-btn-color);
}

.agreementBlock {
  display: flex;
  flex-direction: row;
  max-width: 400px;
  gap: 5px;
  align-items: flex-start;
  margin-left: 9rem;

  @include tablet {
    margin-left: 0;
    margin-bottom: 30px;
  }

  .textAgreement,
  .helperText {
    font-size: 12px;
    color: var(--gray-color);
  }

  .underline {
    text-decoration: underline;
  }

  .offerAgreement {
    border-bottom: var(--gray-color) 1px solid;
  }

  .lockIcon {
    width: 14px;
    height: 14px;
    margin-top: 5px;
  }
}

.helperBlock {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 9rem;
  margin-top: 5px;

  @include tablet {
    align-items: center;
  }

  @media screen and (max-width: map-get($breakpoints, md)) {
    padding-left: unset;
  }

  .helperText {
    color: var(--gray-color);
    font-size: 12px;
  }
}

.textInBtn {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  .underline {
    text-decoration: underline;
  }
}

.instudyIcon {
  height: 18px;
  width: 18px;
}

.wrapperSlot {
  @media screen and (min-width: map-get($breakpoints, md)) {
    margin: 10px 9.5rem 0;
  }
  margin-top: 10px;
  max-width: 500px;
}

.descriptionFontSize {
  font-size: 12px;
}

.controllerContainer {
  display: flex;
  flex-direction: column;
}