.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -100;
}

.remoteUser {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.remoteUserAvatar {
  position: relative;
  width: 90px;
  height: 90px;
  object-fit: cover;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
}
