@import '../../../../app/styles/global/mixins';
@import '../../../../app/styles/global/variables';
@import 'app/styles/breakpoints';

.root {
  display: flex;
  padding: 20px 10px;
  flex-direction: column;
  gap: 12px;
  .required_asterisk {
    color: red;
  }
  min-width: 50vw;
  margin-bottom: 50px;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    height: 100%;
  }
}

.fixed {
  position: static;
}

.error {
  color: red;
}

.row {
  max-width: 100%;
  display: grid;
  grid-template-rows: 0.2fr 0.8fr;
  gap: 4px;

  @include tablet {
    max-width: 100%;
  }
}

.sexGroup {
  display: flex;
  flex-direction: row;
  gap: 25px;

  @include mobile {
    flex-direction: column;
    gap: 10px;
  }
}

.uploadWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 1.5em 0;
}

.imagesWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.unselect {
  user-select: none;
}
