.required_asterisk,
.error {
  color: red;
}

.checkbox {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.checkboxLabel {
  display: flex;
  align-items: center;
}
