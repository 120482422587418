@import '../../../../../app/styles/breakpoints';

.row {
  display: grid;
  align-items: center;
  grid-template-columns: 126px 1fr 258px;
  gap: 25px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    gap: 10px;
  }
}

.fieldLabel {
  color: var(--dark-color);
}