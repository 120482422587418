@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.loading {
  margin-top: 42px;
  display: flex;
  height: 50vh;
  flex-direction: column;
  gap: 100px;
}

.root {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .tableWrapper {
    overflow-x: auto;
  }

  .btnWrapp {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .tableCont {
    overflow-x: auto;
    display: table;
    width: 100%;
    min-width: 1050px;
    //table-layout: fixed;
  }

  .columnsTitle {
    position: relative;

    th {
      padding: 10px 0;
      width: 25%;
    }

    &:before {
      position: absolute;
      content: '';
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #e8e8e8;
    }
  }

  .item {
    height: 70px;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #e8e8e8;
    }
  }

  .tbodyItem {
  }

  .tdItem {
  }

  .searchWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50%;

    @include mobile {
      width: 100%;
    }
  }

  .selectPeriod {
    width: 150px;
  }

  .specialistSearchWrapper {
    display: grid;
    align-items: center;
    grid-template-columns: 250px 200px;
    gap: 10px;

    @include tablet {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
    }
  }

  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navigateToSessionPage {
      display: flex;

      .navigateText {
        display: block;
        color: $color-dot-grey;
        position: relative;

        &:after {
          @include pseudo();
          @include icon-bg();
          position: absolute;
          width: 16px;
          right: -25px;
          top: 0;
          height: 24px;
          background-image: url('../../../1_shared/assets/pictures/arrowRight.svg');
        }
      }
    }
  }
}
