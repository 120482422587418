$min-width: 320px;
$max-width: 1200px;
$padding: 20px;
$padding-mobile: 10px;

$font-main: "Inter", "Arial", sans-serif;
$font-secondary: "Gilroy", "Arial", sans-serif;
$font-cent: "21 Cent", "Georgia", serif;

$title-font-family: $font-secondary;

$base-line-height: 1.5;
$base-font-size: 16px;
$base-bg: #ffffff;

$color-dot-blue: #3c00ff;
$color-dot-dark: #191919;
$color-dot-grey: #a1a1a1;
$color-black: #000000;
$color-white: #ffffff;
$color-blue: #0075ff;
$color-red-error: red;
$color-blue-dark: #151f2c;

$color-text: $color-black;
$color-link: $color-black;
$color-link-hover: $color-dot-blue;
$color-title: $color-dot-dark;

//breakpoint
$mobile-width: 775px;
$tablet-width: 1023px;
$laptop-width: 1199px;

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;
