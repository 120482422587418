#videoContainerCompanion video {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  object-fit: contain;
}

#videoContainerSelf video {
  border-radius: 10px;
  rotate: y 180deg;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

#videoContainerSelf video:not(:first-child) {
  display: none;
}
