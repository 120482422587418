@import 'app/styles/breakpoints';

.root {
  display: grid;
  grid-template-rows: auto auto auto;
  gap: 30px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    gap: 10px;
    padding: 0 10px;
  }
}

.gridBlock {
  display: grid;
  justify-content: center;
  align-items: baseline;
  gap: 72px;
  grid-template-columns: 0.1fr 1fr;
}

.expWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;

  button {
    padding: 8px 10px;
    height: unset;
  }
}

.card {
  display: grid;
  gap: 30px;
  grid-template-columns: 300px auto;

  @media screen and (max-width: map-get($breakpoints, md)) {
    grid-template-rows: 0.2fr auto;
    grid-template-columns: auto;
  }
}

.btnClose {
  max-width: 150px;
}

.wrapperData {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.year {
  font-family: 'Inter SemiBold', sans-serif;
  color: var(--primary-btn-color);
}
