@import 'app/styles/breakpoints';
@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.achievementsCard {
  display: grid;
  grid-template-columns: 0.2fr 0.8fr;
  @media screen and (min-width: map-get($breakpoints, lg)) {
    padding: 2rem 3rem;
    justify-items: center;
  }

  @media screen and (max-width: map-get($breakpoints, md)) {
    grid-template-rows: 0.2fr 0.8fr;
    grid-template-columns: auto;
  }

  @include tablet {
    margin: 25px 0;
  }
}
