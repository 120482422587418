@import 'app/styles/breakpoints';
@import '../../../variables';

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 22px;
  margin-bottom: 1vw;

  @media screen and (max-width: map-get($breakpoints, md)) {
    width: 17vw;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    background: var(--mint-color);
    border-radius: 20px;
    width: 100%;
    padding: 7% 8%;
    margin-bottom: 20px;
  }
}

.reserveInfo {
  display: flex;
  flex-direction: row;
  gap: 50px;
  min-width: 256px;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    justify-content: center;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }
}

.titleInfo {
  font-size: 32px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    font-size: 2.22vw;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 32px;
  }
}

.description {
  font-size: 0.52vw;

  @media screen and (max-width: map-get($breakpoints, md)) {
    font-size: 0.694vw;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 10px;
  }
}

.orderBtn {
  font-size: 1vw;
  height: 49px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    width: 315px;
    font-size: 1.04vw;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 100%;
    font-size: 16px;
  }
}

.subscribeButton {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
  text-align: center;
  padding: 5px 20px;
  width: 100%;
  background-color: var(--gray-color) !important;
  color: var(--white-color);
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscribedButton {
  background-color: var(--secondary-btn-color) !important;
  color: var(--secondary-btn-text-color) !important;
}

.unsubscribeButton {
  color: #cf1322 !important;
  background-color: #fff1f0 !important;
  border-color: #ffa39e !important;
}
