.root {
  background: #ffffff99;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  word-break: break-word;
  max-width: 250px;
  padding: 0 4px;
}

.text {
  max-width: max-content;
}

@media screen and (max-width: 480px) {
  .text {
    display: none;
  }
}
