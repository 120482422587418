@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.footer {
  padding-top: 50px;
  padding-bottom: 80px;
  background-color: var(--footer-bg);

  @include mobile {
    padding-top: 20px;
    padding-bottom: 16px;
  }
}

.footer__subtitle {
  color: $color-dot-grey;
  font-size: 14px;
  line-height: 1.57;
  margin-top: 0;
  margin-bottom: 5px;
}

.footer__logo {
  display: block;
  @include mobile {
    img {
      width: 98px;
      height: 29px;
    }
  }
}

.footer__row {
  display: flex;
  margin-bottom: 50px;

  @include tablet {
    display: block;
    margin-bottom: 0;
  }
}

.footer__half {
  width: 50%;
  @include tablet {
    width: 100%;
    margin-bottom: 40px;

    &--min {
      margin-bottom: 20px;
    }
  }
}

.footer__contacts {
  display: flex;

  @include mobile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 23px;
  }
}

.footer__contact {
  width: 50%;

  p {
    margin-top: 0;
    margin-bottom: 5px;
    color: $color-dot-grey;
    font-size: 14px;
    line-height: 1.57;
  }

  a {
    color: var(--footer-text-color);
    font-size: 16px;
    line-height: 1.5;
  }

  @include mobile {
    width: auto;

    p {
      margin-bottom: 5px;
      font-size: 10px;
      line-height: 2;
    }

    a {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.footer__socials {
  @include list();

  display: flex;
}

.footer__socials-item {
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  a {
    display: block;
  }
}

.footer__nav {
  @include list();

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px 30px;

  @include mobile {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 23px;
  }
}

.footer__nav-link {
  color: var(--footer-text-color);
  font-size: 16px;
  line-height: 1.5;

  @include mobile {
    font-size: 14px;
    line-height: 20px;
  }
}

.footer__copyright {
  color: $color-dot-grey;
  font-size: 10px;
  line-height: 1.8;
  margin-top: 20px;
  margin-bottom: 0;
}

.icon {
  &--vk {
    width: 36px;
    height: 36px;
    background-color: var(--primary-btn-color);
    mask-image: url('../../../assets/pictures/stack/stack.svg#vk');
  }
  &--yt {
    width: 36px;
    height: 36px;
    background-color: var(--primary-btn-color);
    mask-image: url('../../../assets/pictures/stack/stack.svg#yt');
  }
  &--tg {
    width: 36px;
    height: 36px;
    background-color: var(--primary-btn-color);
    mask-image: url('../../../assets/pictures/stack/stack.svg#tg');
  }
  &--spectrum {
    width: 36px;
    height: 36px;
    background-color: var(--primary-btn-color);
    mask-image: url('../../../assets/pictures/stack/stack.svg#spectrum');
  }
}

.footer__form {
  h3 {
    max-width: 450px;
    font-family: $font-main;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.56;
    color: var(--footer-text-color);
    margin-top: 0;
    margin-bottom: 6px;
  }

  form {
    display: flex;
  }

  input {
    padding: 0 10px;
    width: 298px;
    height: 39px;
    font-family: $font-main;
    font-size: 16px;
    color: var(--footer-text-color);
    background: var(--footer-input);
    border-radius: 5px;
    border: var(--border-footer);
  }

  button {
    padding: 10px 20px;
    margin-left: 14px;
    font-family: $font-main;
    font-weight: 600;
    font-size: 16px;
    color: $color-white;
    border-radius: 35px;
    background: var(--primary-btn-color);

    &:hover {
      background-color: var(--primary-btn-hover-color);
    }
  }

  @include mobile {
    h3 {
      max-width: 325px;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
    }

    form {
      max-width: 400px;
      display: block;
    }

    input {
      width: 100%;
      margin-bottom: 10px;
    }

    button {
      width: 100%;
      margin-left: 0;
    }
  }
}
