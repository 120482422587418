.achievement {
  display: flex;
  flex-direction: column;
  width: 64px;
  text-align: center;
  align-items: center;

  .achievementContainer {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: center;
    width: 48px;

    .centerOfAchievement {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        height: unset;
      }
    }
  }

  .title {
    white-space: pre-wrap;
    margin-top: 4px;
    width: 75px;
  }
}

.achievementText {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
}
