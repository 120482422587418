@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';
@import 'app/styles/breakpoints';

.wrapper {
  display: flex;
  justify-content: center;
  margin: 1em;
  font-size: 16px;
  align-items: flex-start;
}

.root {
  display: flex;
  flex-direction: row;
  gap: 25px;

  @include tablet {
    flex-direction: column;
    margin: 0px -40px 0 10px;
  }
}

.leftBlock {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.linkSelector {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  max-width: 25vw;
  margin: 10px 0;
  @media screen and (max-width: map-get($breakpoints, md)) {
    margin: 10px -12px;
  }
}

.infoWrapper {
  min-width: 55vw;
  @media screen and (max-width: map-get($breakpoints, md)) {
    max-width: 90vw;
  }
}

.button {
  font-size: 20px;
}

.buttonSelected {
  font-weight: bold;
}

.closeButton {
  margin: 1em;
  padding: 0;
}

.progressCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}
