.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.appointmentBtn {
  width: 290px;
  padding: 24px 30px;
  font-size: 16px;  
  font-weight: 700; 
  line-height: 1.2;
  color: var(--white-color);
  text-align: center;
}