@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.survey {
  padding-top: 100px;
  padding-bottom: 50px;

  &--psy {
    .survey__subtitle {
      max-width: none;
    }
  }

  @include mobile {
    padding-top: 119px;
    padding-bottom: 20px;
  }
}

.survey__inner {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 80px;
  padding: 67px 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('../../../assets/images/backgrounds/bg-color-1@2x.png');
  border-radius: 999rem;
  background-color: #f3faff;

  .black {
    background-image: url('../../../assets/images/backgrounds/bg-color-1-black@4x.png');
  }

  @include mobile {
    display: block;
    padding: 116px 38px;
    text-align: center;
    background-position: top 0 left 44%;
  }
}

.survey__inner__black {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 20px;
  padding: 67px 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('../../../assets/images/backgrounds/bg-color-1-black@4x.png');
  border-radius: 200px;
  color: white;
  background-color: #101721;

  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 290px;
    background-image: url('../../../assets/images/backgrounds/bg-color-1-black-mobile@2x.png');
    background-position: top 0 left 50%;
  }

  @include mobile {
    margin: 0 auto;
    max-height: 460px;
    max-width: 300px;
    display: flex;
    padding: 116px 10px;
    text-align: center;
  }
}

.survey__heading {
  position: relative;
  z-index: 1;

  @include tablet {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

.survey__title {
  margin-top: 0;
  margin-bottom: 10px;

  @include mobile {
    padding: 0 10px;
    margin-bottom: 0;
  }
}

.survey__inner__black .survey__subtitle {
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  max-width: 380px;
  width: auto;
  margin: 0;
  color: $color-white;

  @include tablet {
    text-align: center;
  }

  @include mobile {
    margin-left: auto;
    margin-right: auto;
  }
}

.survey__container {
  @include mobile {
    padding-left: 37px;
    padding-right: 37px;
  }
}

.survey__btn-wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 22px;
  font-weight: 600;
  max-width: 300px;
  margin-left: 50px;

  @include tablet {
    margin-left: 0;
  }

  position: relative;
  z-index: 1;
}

.survey__btn {
  min-width: 228px;

  @include mobile {
    min-width: 180px;
    padding: 15px 20px;
  }
}

.survey-video__wrap {
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  transform: translateY(-50%);
  border-radius: 999rem;

  @include mobile {
    display: none;
  }
}

.survey-video {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: auto;
  height: auto;
  transform: translateY(-50%);

  @include mobile {
    display: none;
  }
}
