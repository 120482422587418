.video {
  height: inherit;
  width: inherit;
}

.indicatorsContainer {
  position: absolute;
  top: 0;
  right: 24px;
  padding: 10px;
}

.indicators {
  position: relative;
  top: 0;
  left: 0;
  z-index: 10;
}

.micro {
  border-radius: 10px;
  background-color: #ffffff99;
  fill: black;
  stroke: none;
  position: absolute;
  top: 0;
  left: 0;
}

.camera {
  border-radius: 10px;
  background-color: #ffffff99;
  stroke: black;
  position: absolute;
  top: 28px;
  left: 0;
}
