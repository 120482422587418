@import 'app/styles/breakpoints';

.root {
  display: grid;
  width: 100%;

  @media screen and (min-width: map-get($breakpoints, sm)) {
    grid-template-rows: 1fr 0.2fr;
  }
}

.nameAndSpec {
  display: grid;
  grid-template-rows: 0.5fr 0.5fr;
  gap: 25px;
  //display: flex;
  //flex-wrap: wrap;
  //flex-direction: row;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-bottom: 20px;
  }
}

.nameBlock {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    gap: 0;
  }
}

.secondName,
.firstName {
  font-size: 64px;
  vertical-align: bottom;
  line-height: 1.1;

  @media screen and (max-width: map-get($breakpoints, md)) {
    font-size: 40px;
  }
  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 36px;
  }
}

.secondName {
  @media screen and (max-width: map-get($breakpoints, sm)) {
    margin-bottom: 4%;
  }
}

.spec {
  display: grid;
  gap: 5px;
  grid-template-rows: 0.2fr 0.8fr;
}

.infoSpec {
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-items: start;
  gap: 25px;
  align-items: start;
}

.defaultInfo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
}

.infoSpecBloc {
  display: grid;
  gap: 5px;
  grid-template-rows: 0.5fr 0.5fr;
}

.titleInfo {
  font-weight: bold;
}

.buttonSecondary {
  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 12px;
  }
}

.dateBlock {
  display: grid;
  grid-template-rows: auto auto;
  gap: 5px;
}

.superVision {
  background: #7f7fff;
  border: 1px solid #7f7fff !important;
  color: white !important;

  &:hover {
    color: white;
  }
}

.textChips {
  font-size: 12px;
}

.textChipsSuper {
  color: white !important;
}

.specFirstColumn,
.specSecondColumn {
  width: 90%;
  display: flex;
  gap: 20px;
}

.specWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  @media screen and (max-width: map-get($breakpoints, sm)) {
    flex-direction: column;
    gap: 25px;
    margin-bottom: 25px;
  }
}

.specFirstColumn {
  display: flex;
  flex-direction: column;
  max-width: 25%;
  @media screen and (max-width: map-get($breakpoints, sm)) {
    max-width: 100%;
    gap: 25px;
  }

  .specFirstBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 25px 0 0;

    @media screen and (max-width: map-get($breakpoints, sm)) {
      justify-content: flex-start;
      gap: 40%
    }
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    display: flex;
  }
}

.specSecondColumn {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    gap: 25px;
  }
}

.specLineItem {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.specialistPersonInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: map-get($breakpoints, md)) {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    display: flex;
    flex-direction: column;
  }
}
