@import "app/styles/breakpoints";

.root {

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 100% !important;
    overflow: hidden;
    overflow-x: scroll;

    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
}

.previewMobile{
  display: none;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    display: flex;
    justify-content: center;
  }
}

.personImg{
  border-radius: 20px;
  max-width: 260px;
  aspect-ratio: 9/16;
  object-fit: cover;
}

.swiperSlide {
  width: 260px;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    margin-left: 5%;
    margin-bottom: 20px;
  }
}

.previewDesktop{
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    display: none;
  }
}

.preview {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px !important;
  background-color: white;
  border: 1px solid white !important;
  max-width: 260px;
  max-height: 425px;
}

.shortsPhotos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  margin-top: 16px;
  .photoContainer {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    overflow: hidden;
    background: none;
    border: none;
  }

  .shortImg {
    border-radius: 50%;
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
    opacity: 70%;

    &:hover {
      transition: 0.4s;
      opacity: 100%;
    }

    &_selected {
      opacity: 1;
    }
  }
}
