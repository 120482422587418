.root {
  width: 100%;
  height: 100%;
  padding-top: 15vh;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto;
  max-width: 80%;
  font-size: 1.5vmax;
  color: var(--gray-color);
}
