@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';
@import 'app/styles/breakpoints';

.root {
  width: 100%;
  border-bottom: lightgray 1px solid;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.field {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.fieldWrapper {
  border-bottom: lightgray 1px solid;
}

.education {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 95%;

  .error {
    color: red;
  }

  .input {
    gap: 5px;
  }

  @include mobile {
    gap: 5px;
  }
}

.addButton {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-weight: 500;
  color: blue;
  cursor: pointer;
  margin: 20px 0;
  font-size: 14px;
  text-wrap: auto;
  text-align: start;
  word-wrap: break-word;
  white-space: normal;

  .icon {
    stroke: blue;
    stroke-width: 100px;
    font-size: 18px;
  }
}

.saveButton {
  width: 100%;
  margin-top: 20px;
}

.removeButton {
  @media screen and (max-width: map-get($breakpoints, md)) {
    margin: -10px;
  }
}
