.preEndIndicator {
  position: absolute;
  bottom: 20px;
  right: 24px;
  background: #ffffff99;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  gap: 10px;
}
