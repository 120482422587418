.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  margin-bottom: 50px;
}

.saveButton {
  width: 100%;
}