@import "app/styles/breakpoints";

.achievementsCardsList {
  display: flex;
  flex-direction: column;
  gap: 19px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    flex-direction: row;
    gap: 10px;
    align-items: baseline;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 20px;
  }
}