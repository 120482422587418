@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.faq {
  padding: 50px 0;
  margin-top: 50px;

  @include tablet {
    margin-top: 0;
  }
}

.faq__inner {
  display: flex;
  padding-bottom: 50px;

  @include tablet {
    display: block;
  }

  @include mobile {
    padding-bottom: 0;
  }
}

.faq__half {
  width: 50%;

  @include tablet {
    width: 100%;
  }
}

.faq__title {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 10px;
}

.faq__subtitle {
  color: $color-dot-grey;

  @include tablet {
    margin-bottom: 20px;
  }
}

.faq__accordion {
  @include list();
}

.faq__accordion-item {
  border-bottom: 1px solid $color-dot-grey;

  &:first-of-type {
    border-top: 1px solid $color-dot-grey;
  }

  &.is-active {
    .faq__accordion-link {
      &::after {
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }
}

.faq__accordion-link {
  display: block;
  position: relative;
  color: $color-dot-dark;
  font-size: 18px;
  line-height: 1.55;
  padding: 10px 44px 10px 10px;

  &::after {
    @include pseudo();

    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;

    @include icon-bg();

    mask-image: url("../../../assets/pictures/stack/stack.svg#plus");
    background-color: var(--primary-btn-color);
    transition: 0.3s all;
  }

  @include mobile {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 14px;
    line-height: 1.43;
  }
}

.faq__accordion-inner {
  padding: 10px;
  color: $color-dot-dark;

  p {
    margin-top: 0;
  }
}
