.root {
  display: grid;
  justify-items: center;
  grid-template-rows: auto auto auto;
  gap: 16px;
}

.wrapInfo {
  position: relative;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 4px;
}

.dot {
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: var(--gray-color);
  border-radius: 50%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
