@import 'app/styles/breakpoints';

.root {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  justify-content: start;
  gap: 13px;
  height: max-content;
  width: 100%;

  @media screen and (max-width: map-get($breakpoints, md)) {
    justify-content: start;
    margin-left: 0;
    height: 100%;
    gap: 14px;
    max-width: 90vw;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    justify-content: stretch;
  }
}

.btnNext,
.infoBtn {
  @media screen and (max-width: map-get($breakpoints, md)) {
    height: 40px;
    font-size: 12px;
    min-width: 50%;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 100%;
  }
}

.btnSubmit {
  @media screen and (max-width: map-get($breakpoints, md)) {
    height: 40px;
  }
}

.darkBtn {
  background: transparent;
  border: 1px solid var(--primary-btn-color);
  color: var(--primary-btn-color);
}

.subscribeButton {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
  text-align: center;
  padding: 5px 20px;
  width: 100%;
  border-radius: 2rem;
  background-color: var(--gray-color) !important;
  color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: map-get($breakpoints, md)) {
    font-size: 12px;
    min-width: 183px;
  }
}

.subscribedButton {
  background-color: var(--secondary-btn-color) !important;
  color: var(--secondary-btn-text-color) !important;
}

.unsubscribeButton {
  color: #cf1322 !important;
  background-color: #fff1f0 !important;
  border-color: #ffa39e !important;
}
