@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 30px;
}

.wrappInput {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.wrappCode {
  display: flex;

  :global(.ant-input) {
    aspect-ratio: 1 / 1 !important;
    height: auto !important;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
  }
}

.btns {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: start;

  @include tablet() {
    flex-direction: column;
  }
}

.inputPin {
  max-width: 35px;
}


.repeatCode {
  display: flex;
  flex-direction: column;
  gap: 5px;
}