@import 'app/styles/global/variables';
@import 'app/styles/global/mixins';
@import 'app/styles/breakpoints';

.root {
  margin-bottom: 50px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.field {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.fieldWrapper {
  border-bottom: lightgray 1px solid;
}

.workExperience {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 95%;

  @include tablet {
    gap: 5px;
  }
}

.addButton {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-weight: 500;
  color: blue;
  cursor: pointer;
  margin-top: 20px;
  font-size: 14px;

  .icon {
    stroke: blue;
    stroke-width: 100px;
    font-size: 18px;
  }
}

.saveButton {
  width: 100%;
  margin-top: 20px;
}

.dateBlock {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
  width: 75%;

  .text {
    margin: 0.4rem 0;
  }
}

.error {
  margin: -15px 0 0 0;
  color: red;

  @include tablet() {
    margin: 0;
  }
}

.removeButton {
  @media screen and (max-width: map-get($breakpoints, md)) {
    margin: -10px;
  }
}
