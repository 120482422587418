@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.specialists {
  padding: 50px 0;
  background-color: var(--footer-bg);

  @include mobile {
    padding-top: 20px;
    padding-bottom: 32px;
  }
}

.specialists__inner {
  display: grid;
  grid-template-columns: 43% 57%;
  grid-template-rows: auto 1fr;

  @include tablet {
    display: block;
  }
}

.specialists__desc {
  display: flex;
  flex-direction: column;
}

.specialists__title {
  max-width: 311px;
  color: var(--footer-text-color);
  margin-top: 0;
  margin-bottom: 10px;
}

.specialists__subtitle {
  max-width: 311px;
  color: $color-dot-grey;
  margin-top: 0;
  margin-bottom: 35px;
}

.specialists__benefits {
  @include list();

  margin-bottom: 42px;

  @include mobile {
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.specialists__benefits-item {
  position: relative;
  color: var(--footer-text-color);
  padding-left: 29px;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    @include pseudo();

    top: 0;
    left: 0;
    width: 24px;
    height: 24px;

    @include icon-bg();
  }

  &--exp {
    &::before {
      mask-image: url('../../../assets/pictures/stack/stack.svg#glasses');
      background-color: var(--primary-btn-color);
    }
  }

  &--edu {
    &::before {
      mask-image: url('../../../assets/pictures/stack/stack.svg#diploma');
      background-color: var(--primary-btn-color);
    }
  }

  &--ru {
    &::before {
      mask-image: url('../../../assets/pictures/stack/stack.svg#message');
      background-color: var(--primary-btn-color);
    }
  }

  @include mobile {
    margin-bottom: 0;
    margin-right: 26px;
    padding-left: 0;
    padding-top: 29px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.specialists__btn {
  align-self: baseline;

  @include mobile {
    order: -1;
    width: 100%;
    max-width: 400px;
  }
}

.specialists__project {
  margin-top: auto;

  img {
    width: 101px;
    height: 28px;
  }

  @include mobile {
    margin-top: 0;
    text-align: center;
  }
}

.specialists__project-title {
  max-width: 214px;
  font-family: $font-secondary;
  color: $color-dot-grey;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 12px;

  @include mobile {
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.specialists__list-wrapper {
  grid-column: 2 / 3;
  grid-row: 1 / 3;

  @include tablet {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  @include mobile {
    width: 100%;
    overflow-y: auto;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
}

.specialists__list {
  @include list();

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px 25px;

  @include mobile {
    grid-template-columns: auto;
    grid-auto-flow: column;
    gap: 20px;
  }
}

.specialists__item {
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  h3 {
    font-weight: 400;
    color: var(--footer-text-color);
    font-size: 16px;
    line-height: 1.5;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  p {
    color: $color-dot-grey;
    font-size: 12px;
    line-height: 1.5;
    padding-right: 12px;
    margin: 0;
  }

  @include mobile {
    width: 174px;
  }
}

.specBtn {
  background-color: transparent;

  svg {
    g {
      path {
        fill: var(--icon-text-spec);
      }
      g {
        circle {
          fill: var(--icon-bg-spec);
        }
        path {
          stroke: var(--icon-bg-spec);
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}
