@import "app/styles/global/mixins"; // В стилях нам необходимо импортровать миксины и переменные, и все что здесь используется
@import "app/styles/global/variables";

.specialist-quote {
  padding-top: 25px;
  padding-bottom: 25px;
}

.specialist-quote__container {
  display: flex;
  justify-content: space-between;

  @include tablet {
    display: block;
  }
}

.specialist-quote__content {
  position: relative;
  max-width: 645px;
  padding-left: 87px;
  margin-left: 20px;

  &::before {
    @include pseudo();
    top: 8px;
    left: 6px;
    width: 38px;
    height: 32px;
    mask-image: url("../../../1_shared/assets/pictures/stack/stack.svg#quote");
    background-color: var(--primary-btn-color);
    background-size: contain;
    background-repeat: no-repeat;
  }

  @include mobile {
    margin-left: 0;
    padding-left: 0;

    &::before {
      left: auto;
      right: 8px;
      top: -40px;
    }
  }
}

.specialist-quote__title {
  margin-top: 0;
  margin-bottom: 20px;

  @include mobile {
    padding-right: 55px;
    margin-bottom: 10px;
    max-width: 645px;
  }
}
