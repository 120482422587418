@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.feedback {
  background-color: #FFFDE9;
  padding-top: 50px;
  padding-bottom: 63px;

  @include mobile {
    padding-top: 20px;
    padding-bottom: 10px;
  }
}

.feedback__container {
  @include tablet {
    padding-left: 0;
    padding-right: 0;
  }
}

.feedback__heading {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin-bottom: 60px;

  @include tablet {
    display: block;
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mobile {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
  }
}

.feedback__title {
  margin: 0;

  span {
    position: relative;
    font-family: $font-cent;
    font-weight: 400;
    font-style: italic;

    &::after {
      @include pseudo;

      left: 3px;
      bottom: 4px;
      width: 140px;
      height: 5px;
      mask-image: url("../../../assets/pictures/stack/stack.svg#title-line-straight");
      background-color: var(--primary-btn-color);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  @include tablet {
    margin-bottom: 30px;
  }

  @include mobile {
    span {
      &::after {
        left: 1px;
        bottom: -2px;
        width: 90px;
      }
    }
  }
}

.feedback__subtitle {
  color: $color-dot-grey;
  margin: 0;

  @include tablet {
    max-width: 400px
  }
}

.feedback__list-wrapper {

  @include tablet {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mobile {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
}

.feedback__list {
  @include list;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;

  @include tablet {
    grid-template-columns: auto;
    grid-auto-flow: column;
    gap: 20px;
    align-items: start;
  }
}

.feedback__item {
  position: relative;
  padding-top: 51px;

  p {
    color: $color-black;
    margin: 0 0 10px 0;
  }

  span {
    color: $color-dot-grey;
    font-size: 14px;
    line-height: 1.28;
  }

  &::before {
    content: "„";
    position: absolute;
    left: 0;
    top: 0;
    font-family: $font-cent;
    color: var(--primary-btn-color);
    font-size: 64px;
    line-height: 0.22;
    font-weight: 500;
  }

  &--w-plus {
    &::after {
      @include pseudo();

      position: absolute;
      top: 75px;
      right: 36px;
      width: 24px;
      height: 23px;

      @include icon-bg();

      mask-image: url("../../../assets/pictures/stack/stack.svg#plus1");
      background-color: var(--primary-btn-color);
    }
  }

  &--w-heart {
    &::after {
      @include pseudo();

      position: absolute;
      bottom: -7px;
      right: 22px;
      width: 32px;
      height: 29px;

      @include icon-bg();

      mask-image: url("../../../assets/pictures/stack/stack.svg#heart");
      background-color: var(--primary-btn-color);
    }
  }

  @include tablet {
    width: 250px;
    padding-right: 30px;
  }

  @include mobile {
    width: 220px;
    padding-bottom: 30px;

    &::after {
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
    }
  }
}




